<template>
    <div>
      <v-row justify="center">
        <div v-if="dialog && bankUser">
          <v-dialog
            :value="dialog"
            persistent
            max-width="50%"
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar>
                <v-btn icon @click="$emit('onCloseDialog')">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title class="text-uppercase">
                  {{
                    !bankUser._id ? $t("title.create_bankUser") : $t("title.update_bankUser")
                  }}</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn :disabled="loading" text @click="validate">
                    {{ !bankUser._id ? $t("button.create") : $t("button.update") }}
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="bankUser.bank_id"
                          :items="optionBankUser"
                          item-value="_id"
                          item-text="name"
                          :label="$t('input.bank_name')"
                          class="app-name"
                          style="min-width: 100%;"
                          >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="bankUser.account_name"
                          :rules="rules.account_name"
                          :label="$t('input.account_name')"
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="bankUser.account_number"
                          :rules="rules.account_number"
                          :label="$t('input.account_number')"
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="bankUser.account_info"
                          :label="$t('input.account_info')"
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="bankUser.currency_type"
                          item-value="id"
                          item-text="name"
                          :items="items"
                          :rules="rules.currency_type"
                          :label="$t('input.currency_type')"
                          hide-details="auto"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="bankUser.order"
                          :rules="rules.order"
                          :label="$t('input.order')"
                          type="number"
                          hide-details="auto"
                        />
                      </v-col>
                      <!-- <v-col cols="6">
                        <v-switch
                          :label="$t('input.is_enable')"
                          v-model="bankUser.is_enable"
                        />
                      </v-col> -->
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </v-row>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      dialog: {
        type: Boolean,
      },
      optionBankUser: {
        type: Array,
        default: () => {
          return [];
        },
      },
      loading: {
        type: Boolean,
      },
      darkmode: {
        type: Boolean,
      },
      rules: {
        type: Object,
        default: function () {
          return {
            bank_id: [(v) => !!v || "Bank name is required"],
            account_name: [(v) => !!v || "Account Name is required"],
            account_number: [(v) => !!v || "Account Number is required"],
            currency_type: [(v) => !!v || "Currency Type is required"],
            order: [(v) => !!v || "Order is required"],
          };
        },
      },
      isValidate: {
        type: Boolean,
        default: true,
      },
      bankUser: {
        type: Object,
        default: () => {
          return {
            user_id: null,
            bank_id: null,
            account_name: null,
            account_number: null,
            account_info: null,
            is_active: true,
            is_enable: true,
            currency_type: null,
            order: null,
          };
        },
      },
    },
    data() {
      return {
        items: [
          { name: "DOLLAR", id: 1 },
          { name: "KHR", id: 2 },
          { name: "VN", id: 4 },
        ],
      };
    },
    computed: {
      valid: {
        // getter
        get: function () {
          return this.isValidate;
        },
        // setter
        set: function (newValue) {
          this.$emit("update:isValidate", newValue);
        },
      },
    },
    methods: {
      validate() {
        if (this.$refs.form.validate()) {
          this.$emit("submit");
        }
      },
  
      remove(item) {
        this.bankUser.permissions.splice(this.bankUser.permissions.indexOf(item), 1);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>
  